var prodcat = prodcat || {};

prodcat.data = prodcat.data || {};
var site = site || {};

site.template = site.template || {};

Drupal.behaviors.quickshop = (function ($) {
  var lastBP;
  var qsContainerActiveClasses = (function () {
    var classes = 'active';

    for (var i = 1; i <= 10; i++) {
      classes += ' active--' + i;
    }

    return classes;
  })();
  // Modernizr.touch = true;
  var behavior = {
    $grids: $(),
    initGrid: function ($grids, size) {
      if (_.isUndefined(size)) {
        size = Unison.fetch.now().name;
      }
      var isMobile = !_.isUndefined(size) && size.indexOf('small') !== -1;
      var self = this;

      // Insert quickshop placeholder divs into product grids.
      // On mobile there is a quickshop placeholder after each product thumb.
      // On desktop there's a shared one after each row of product thumbs.

      $grids.has('.product-grid__item').each(function () {
        // First remove any existing quickshop wrappers in the grid.
        self.reset($(this));

        var i = 0;
        var classes = '';
        var rows = [];
        var isCarousel = $(this).hasClass('product-grid--carousel');
        var $row = $();
        var $container = $(
          '<div class="js-quickshop-container quickshop-wrapper" role="presentation" aria-live="polite" />'
        );
        var $items = $('.product-grid__item:visible', this);
        var iLen = $items.length;

        $items.each(function (index) {
          var prodID = $(this).data('product-id');

          if (prodID) {
            classes += ' js-quickshop-container--' + $(this).data('product-id');
          }

          // For carousels, we just need all the classes
          if (isCarousel) {
            return;
          }

          // For mobile simply add the quickshop wrapper immediately following
          // the item:
          else if (isMobile) {
            $(this).after($container.clone()).next().addClass(classes);
            classes = '';
            rows.push($(this));
          }

          // For desktop add a new wrapper after every row of appendAfter items:
          else {
            var span = 1;
            var appendAfter = 3;

            if ($(this).data('quickshop-after')) {
              appendAfter = $(this).data('quickshop-after');
            }
            if ($(this).data('colspan')) {
              span = $(this).data('colspan');
            }
            i += span;
            $row = $row.add(this);
            if (i === appendAfter || index + 1 === iLen) {
              if (i === appendAfter) {
                $(this).addClass('last'); // css targets this rather than nth-of-type
              }
              $(this).after($container.clone()).next().addClass(classes);
              rows.push($row);
              i = 0;
              $row = $();
              classes = '';
            }
          }
        });

        if (isCarousel) {
          // slick wraps our $items in its own stuff so we have to treat those
          // as items if they've been set.
          var $slides = $('.slick-slide', this);

          rows = $slides.length ? [$slides] : [$items];
          $(this).after($container.clone()).next().addClass(classes);
        }

        $(this).data('product-grid-rows', rows);
        self.$grids.add($(this));
      });
    },

    reset: function ($grid) {
      var $container = $grid.hasClass('product-grid--carousel') ? $grid.parent() : $grid;

      $('.js-quickshop-container', $container).remove();
      $('.product-brief', $grid).removeClass('active');
    },

    attach: function (context) {
      // These use Unison, and jQuery fires its ready event before Unison, so
      // use an interval to wait till it's ready.
      var size = Unison.fetch.now() ? Unison.fetch.now().name : 'medium';
      var intvl;
      var self = this;

      this.initGrid($('.product-grid--quickshop', context), size);
      if (!Unison.fetch.now()) {
        intvl = setInterval(function () {
          if (Unison.fetch.now()) {
            size = Unison.fetch.now().name;
            self.initGrid($('.product-grid--quickshop', context), size);
            clearInterval(intvl);
          }
        }, 50);
      }
    }
  };

  $(document).on('click', '.js-quickshop-trigger', function (event) {
    event.preventDefault();
    $(this).attr('aria-expanded', 'true');
    $(this).closest('.js-product').trigger('product.quickshop.launch');
    $('.js-access-content-expanded').focus();
    var inventory_item = $('.product__inventory-status-item:visible').text();
    var $activeItem = $(document.activeElement);

    if ($activeItem.hasClass('button--disabled')) {
      $activeItem.attr({
        'aria-label': inventory_item,
        role: 'presentation'
      });
    }
    site.restrict_navigation($('.js-access-content-expanded'));
  });

  // Global events (only bound once when the script loads):
  $(document).on('product.quickshop.close', '.js-product', function () {
    var productID = $(this).data('product-id');
    var $grid = $(this).closest('.product-grid');
    var $wrapper = $grid.closest('.product-grid-wrapper');
    var $container = $('.js-quickshop-container--' + productID, $wrapper);

    $(this).removeClass('active');
    $('.js-quickshop-trigger').attr('aria-expanded', 'false');

    $container.slideUp(400, function () {
      $(this).removeClass(qsContainerActiveClasses).find('.js-quickshop').remove();
      $grid.trigger('product.quickshop.afterAnimation');
    });
  });

  $(document).on('product.quickshop.launch', '.js-product', function () {
    var productID = $(this).data('product-id');
    var product = prodcat.data.getProduct(productID);
    var $product = $(this);
    var $grid = $product.closest('.product-grid');
    var isCarousel = $grid.hasClass('product-grid--carousel');
    var $gridItem = $product.closest('.product-grid__item');
    var $products = $('.product-brief', $grid);
    var $qsContext = isCarousel ? $grid.parent() : $grid;
    var $containers = $('.js-quickshop-container', $qsContext);
    var $container = $containers.filter('.js-quickshop-container--' + productID);
    var $active = $containers.filter('.active');
    var $qsProduct = $();
    var alreadyActive = $product.hasClass('active');
    var speed = 400;
    var isMobile = Unison.fetch.now().name === 'small';
    // @TODO: mobile appears to not have quickshop triggers on the MPP by design.
    // Check that's correct.
    // var template = 'product_quickshop';
    var template = isMobile ? 'product_quickshop' : 'product_quickshop';
    // Used to calculate quickshop scroll
    var qsTop = 0;
    var qsOffset = -200;

    // If already active, close and return.
    if (alreadyActive) {
      $product.trigger('product.quickshop.close');

      return;
    }

    // If the product has data-available-skus set that means to only display the
    // skus available in that attribute.
    var availableSkusString = $product.data('available-skus');

    if (availableSkusString) {
      var skus = [];

      _.each(_.map(availableSkusString.split(','), $.trim), function (skuID) {
        _.each(product.skus, function (sku) {
          if (sku.SKU_ID === skuID) {
            skus.push(sku);
          }
        });
      });
      if (skus.length) {
        product.skus = skus;
      }
    }

    // Render quickshop
    var rendered = site.template.get({
      name: template,
      data: product
    });

    // Fire QV tagging event
    if (!_.isUndefined(window.CATEGORY_ID)) {
      $(window).trigger('MPP:productQV', [window.CATEGORY_ID + '~' + productID]);
    }

    // Populate and show the container we're activating
    $active.hide();
    $('.js-quickshop', $container).remove();
    $container.append(rendered).show();

    if (Drupal.settings.common.enable_preorder) {
      if (site.templates && site.templates['product_pre_order']) {
        var preorderMpp = site.template.get({
          name: 'product_pre_order',
          data: product
        });

        $('.js-product', $container).append(preorderMpp).show();
      }
    }
    $qsProduct = $('.js-product', $container);
    $qsProduct.trigger('product.init');
    $qsProduct.find('select.js-sku-menu').trigger('change');

    $('.js-quickshop-close', $container).on('click keydown', function (event) {
      var keyCode = site.getKeycode(event);

      switch (keyCode) {
        case 1:
        case 13:
          event.preventDefault();
          $product.trigger('product.quickshop.close');
          break;
        case 9:
          if ($('.js-product-shade:visible').length > 0) {
            $('.js-product-shade:visible').first().focus();
            site.restrict_navigation($('.js-quickshop'));
          }
          break;
      }
    });

    qsTop = $container.offset().top;

    // Here so other scripts can do computations with the final height of the
    // grid before the animation:
    $grid.trigger('product.quickshop.beforeAnimation');

    // Scroll animation:
    $('html, body').animate(
      {
        scrollTop: qsTop + qsOffset
      },
      {
        duration: speed
      }
    );

    $products.removeClass('active');
    $product.addClass('active');

    // Review Logic
    // @TODO: This should not to a new rpc call each time you open a quickshop,
    // it should cache them.
    var numericProductID = productID.replace(/[A-Za-z$-]/g, '');

    window.generic.jsonrpc.fetch({
      method: 'reviews.latestMppProductReview',
      params: [{ PROD_BASE_ID: numericProductID }],
      onSuccess: function (r) {
        var resultData = r.getValue();

        if (resultData) {
          if (resultData.TITLE) {
            $('.js-headline', $container).text(resultData.TITLE);
          }
          if (resultData.REVIEW_TEXT) {
            $('.js-quote', $container).text(resultData.REVIEW_TEXT);
          }
          var authorLine = resultData.DISPLAY_NAME;

          if (authorLine) {
            authorLine += ', ';
          } else {
            authorLine = '';
          }
          authorLine += resultData.REVIEWER_LOCATION;
          $('.js-author', $container).text(authorLine);

          // Turn the rating into a percentage
          var ratingWidth = (resultData.RATING / 5.0) * 100 + '%';

          $('.js-rating', $container)
            .css({
              width: ratingWidth
            })
            .removeClass('hidden');
        } else {
          $('.product-quote', $container).remove();
        }
      },
      onFailure: function (/* r*/) {
        // console.log(r);
        // console.log(r.getError());
      }
    });
    // End Review Logic

    // Determine which item is active in the row so css can position the arrow.
    // The arrow is inside of the quickshop rather than relative to the thumb
    // so that it is part of the expand/collapse animation.

    var i;

    if (isCarousel) {
      var $carousel = $('.js-product-carousel', $grid).first();
      var slideIndex = $carousel.slick('slickCurrentSlide');

      i = $gridItem.data('slick-index') - slideIndex + 1;
    } else {
      i = $gridItem.prevUntil('.js-quickshop-container').filter('.js-grid-item:visible').length + 1;
    }

    // Quickshop animation:
    // 1. If current quickshop is already active, no need to animate anything
    if ($container.hasClass('active')) {
      $container.removeClass(qsContainerActiveClasses).addClass('active active--' + i);

      return;
    }

    // 2. If there are other active quickshops, animate them closed
    $active.show().slideUp(speed, function () {
      $(this).removeClass(qsContainerActiveClasses).find('.js-quickshop').remove();
      $grid.trigger('product.quickshop.afterAnimation');
    });

    // 3. Hide quickshop and animate it open
    $container
      .addClass('active active--' + i)
      .hide()
      .slideDown(speed, function () {
        // Reset jQuery's stupid garbage:
        $(this).css({
          display: '',
          overflow: ''
        });
        $grid.trigger('product.quickshop.afterAnimation');
      });
  });

  $(document).on('grid.reflow', '.product-grid--quickshop', function () {
    behavior.initGrid($(this));
  });

  Unison.on('change', function (bp) {
    // Do nothing when toggling between large and medium.
    if ((lastBP === 'large' && bp.name === 'medium') || (lastBP === 'medium' && bp.name === 'large')) {
      return;
    }
    lastBP = bp.name;

    behavior.initGrid($('.product-grid--quickshop'), bp.name);
  });

  return behavior;
})(jQuery);
